import React from 'react';
 import video from '../../images/yurknopka_prev.mp4';
 import './Showcase.scss';

function Showcase() {

  return (
 <div className='wrapper wrapper_showcase'>
<video controls >
      <source src={video} type="video/mp4"/>
</video>
 </div>
  )
}

export default Showcase