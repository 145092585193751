import React from 'react'
import Showcase from '../components/Showcase';
import Header from '../components/Header';

function showcases() {
  return (
    <div className="container">
    <Header/>
    <Showcase/>
    </div>
  )
}

export default showcases