import React, {useState} from 'react';
import { Link } from "gatsby";
import { StaticImage } from 'gatsby-plugin-image';
import './Header.scss';
import { FaBars } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';

function Header() {

  const [menu, setMenu] = useState(false);

  return (
    <header>
      <div className="nav">
        <div className="nav_logo">
          <h1>Юркнопка</h1>
          <span>Beta</span>
        </div>
        <ul className="nav_menu">
          <Link className="nav_menu_item" to="/">Главная</Link>
          <Link className="nav_menu_item" to="/about">Что это?</Link>
          <Link className="nav_menu_item" to="/business">B2B</Link>
          <Link className="nav_menu_item" to="/showcases">Обзор продукта</Link>
        </ul>
        <div className='nav_resp' onClick={()=>setMenu(!menu)}>
          {menu ? <AiOutlineClose/> : <FaBars/>}
        </div>
      </div>

      <ul className={menu ? "nav_menu_resp_active":"nav_menu_resp"}>
          <Link className="nav_menu_item" to="/">Главная</Link>
          <Link className="nav_menu_item" to="/about">Что это?</Link>
          <Link className="nav_menu_item" to="/business">B2B</Link>
          <Link className="nav_menu_item" to="/showcases">Обзор продукта</Link>
      </ul>
    </header>
  )
}

export default Header